import React from 'react';
import styled from 'styled-components';
import { FaHtml5, FaCss3, FaJs, FaReact, FaWhatsapp, FaInstagram, FaGithub, FaLinkedin } from 'react-icons/fa';

import profileImage from '../assets/eu.jpg';

const MainContainer = styled.div`
  text-align: center;
  padding: 50px;
`;

const ProfileImage = styled.img`
  width: 150px;
  border: solid 5px #F68516;
  border-radius: 50%;
`;

const SpecializationsContainer = styled.div`
  margin-top: 20px;
`;

const SpecializationIcon = styled.span`
  margin: 0 10px;
  font-size: 30px;
  color: #007bff;
`;

const SocialIconsContainer = styled.div`
    margin-top: 20px;
`;
const Nome = styled.h1`
  color: #F68516;
`;
const Skil = styled.h2`
  color: #4e0361;
`;
const Paragrafo = styled.p`
  font-size: 18px;
  color: #3f3e05;
`;
const Laranja = styled.p`
  color: #df7008;
  display: inline; /* Adicionado para alinhar na mesma linha */
`;

const Ciano = styled.p`
  color: #078BFB;
  display: inline; /* Adicionado para alinhar na mesma linha */
`;

const Amarelo = styled.p`
  color: #f0e76a;
  display: inline; /* Adicionado para alinhar na mesma linha */
`;

const SocialIcon = styled.a`
  margin: 0 10px;
  color: #333;
  font-size: 24px;
  text-decoration: none;

  &:hover {
    color: #007bff;
  }
`;

const MainSection = () => {
  return (
    <MainContainer>
      <ProfileImage src={profileImage} alt="Foto do Especialista" />
      <Nome>Wilton Gonçalves Santana</Nome>
      <Skil>Desenvolvedor Web</Skil>
      <Paragrafo><strong>Sou um apaixonado por diversas tecnologias que impulsionam a experiência online.<br/>
         Ao longo da minha aprendizagem,<br/> 
         tenho mergulhado profundamente em quatro pilares essenciais do desenvolvimento web:<Laranja> HTML</Laranja>, <Ciano>CSS</Ciano>, <Amarelo>JavaScript</Amarelo> e <Ciano>React.js</Ciano>.</strong></Paragrafo>
      <SpecializationsContainer>
      <Skil>Tecnologias</Skil>
        <SpecializationIcon><FaHtml5 /></SpecializationIcon>
        <SpecializationIcon><FaCss3 /></SpecializationIcon>
        <SpecializationIcon><FaJs /></SpecializationIcon>
        <SpecializationIcon><FaReact /></SpecializationIcon>
      </SpecializationsContainer>
      <SocialIconsContainer>
        <SocialIcon href="https://api.whatsapp.com/send/?phone=5511941444178&text=Ol%C3%A1%2C+Vi+seu+numero+seu+site+e+quero+um+or%C3%A7amento.&type=phone_number&app_absent=0" target="_blank">
          <FaWhatsapp />
        </SocialIcon>
        <SocialIcon href="https://www.instagram.com/wiltongoncalves.dev/" target="_blank">
          <FaInstagram />
        </SocialIcon>
        <SocialIcon href="https://github.com/Wilton-compactcode" target="_blank">
          <FaGithub />
        </SocialIcon>
        <SocialIcon href="https://www.linkedin.com/in/wilton-goncalves-santana-dev-web/                               " target="_blank">
          <FaLinkedin />
        </SocialIcon>
      </SocialIconsContainer>
    </MainContainer>
  );
};

export default MainSection;
